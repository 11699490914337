<template>
    <div id="app" data-content-area="1200px">
        <AppLoader :isHidden="!userInfo.loginInProcess" />
		<AppLogin v-if="!userInfo.isLoggedIn" />
        <template v-else>
            <div>
                <PageHeader></PageHeader>
                <router-view/>
            </div>
        </template>
    </div>
</template>

<script>
import PageHeader from './components/PageHeader.vue';
import AppLoader from './components/AppLoader.vue';
import AppLogin from './components/AppLogin.vue';

export default {
    data: () => {
        return {

        }
    },
    components: {
        PageHeader,
        AppLoader,
        AppLogin
    },
    computed: {
        userInfo() {
            return this.$store.getters['getUserInfo'];
        }
    },
    created() {
        this.$store.dispatch('fetchCategories');
        this.$store.dispatch('fetchStaticContent');
        this.$store.dispatch('fetchCourses');
        this.$store.dispatch('fetchMetaInfo');
        this.$store.dispatch('fetchOrders');
        // this.$store.dispatch('fetchImageStorage');

        if (!!localStorage.getItem('user')) {
            this.$store.dispatch('login', JSON.parse(localStorage.getItem('user')));
        }
    }
}
</script>

<style lang="less">
    @import "./assets/less/index.less";
</style>
