import Vue from 'vue'
import Vuex from 'vuex'
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { getAuth, signOut, signInWithEmailAndPassword } from "firebase/auth";
// import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

Vue.use(Vuex)

const app = initializeApp({
    apiKey: "AIzaSyD18cTsWAJ_EHtJkcQnLx8XOPpYA6Gzr0k",
    authDomain: "csf-site-a979e.firebaseapp.com",
    projectId: "csf-site-a979e",
    storageBucket: "csf-site-a979e.appspot.com",
    messagingSenderId: "950006402791",
    appId: "1:950006402791:web:2ae79455b023bffe115c42"
});

const DB = getFirestore(app);
const AUTH = getAuth();
// const STORAGE = getStorage();
// const storageRef = ref(STORAGE, '/');

// Controllers
function getColectionFromDatabase(c) {
	return getDocs(collection(DB, c));
}
function getDocFromDatabase(c, d) {
	return getDoc(doc(DB, c, d));
}
function setLs(key, value) {
	localStorage.setItem(key, JSON.stringify(value));
}
// function getLs(key) {
// 	return JSON.parse(localStorage.getItem(key));
// }
function removeFromLs(key) {
	localStorage.removeItem(key);
}

export default new Vuex.Store({
    namespaced: true,
	state: {
		categories: [],
		courses: [],
		isCatalogLoading: true,
        metaInfo: {},
		staticPageContent: {
			home: '',
			about: '',
            courses: '',
			contacts: '',
            franchise: '',
			gallery: '',
			streams: '',
			generalInfo: '',
            terms: ''
		},
		user: {
			loginInProcess: false,
			isLoggedIn: false,
			errorMsg: ''
		},
        orders: [],
		imageStorage: []
	},
	getters: {
		getImageStorage(state) {
			return state.imageStorage;
		},	
        getOrders(state) {
            return state.orders;
        },
        getMetaInfo(state) {
            return state.metaInfo;
        },
		getCategories(state) {
			return state.categories;
		},
		getCourses(state) {
			return state.courses;
		},
		getHomepageContent(state) {
			return state.staticPageContent.home;
		},
		getAboutpageContent(state) {
			return state.staticPageContent.about;
		},
		getStreamspageContent(state) {
			return state.staticPageContent.streams;
		},
		getTermspageContent(state) {
			return state.staticPageContent.terms;
		},
		getCoursespageContent(state) {
			return state.staticPageContent.courses;
		},
		getGallerypageContent(state) {
			return state.staticPageContent.gallery;
		},
		getFranchisepageContent(state) {
			return state.staticPageContent.franchise;
		},
		getContactspageContent(state) {
			return state.staticPageContent.contacts;
		},
		getGeneralInfo(state) {
			return state.staticPageContent.generalInfo;
		},
		getUserInfo(state) {
			return state.user;
		},
		getOptionById: (state) => (id) => {
			for (let i = 0; i < state.categories.length; i++) {
				for (let j = 0; j < state.categories[i].list.length; j++) {
					if (state.categories[i].list[j].id == id) {
						return JSON.parse(JSON.stringify(state.categories[i].list[j]));
					}
				} 
			}
		}
	},
	mutations: {

	},
	actions: {
		fetchImageStorage(context) {
			listAll(storageRef)
				.then(res => {
					res.prefixes.forEach(prefixe => {
						listAll(prefixe)
							.then(prefixeRes => {
								prefixeRes.prefixes.forEach(prefixe2 => {
									listAll(prefixe2)
										.then(prefixeRes2 => {
											prefixeRes2.items.forEach(item => {
												getDownloadURL(item)
													.then(url => {
														context.state.imageStorage.push(url);
													})
											})
									})
								})
								prefixeRes.items.forEach(item => {
									getDownloadURL(item)
										.then(url => {
											console.log(111);
											context.state.imageStorage.push(url);
										})
								})
						})
					})
					res.items.forEach(item => {
						getDownloadURL(item)
							.then(url => {
								context.state.imageStorage.push(url);
							})
					})
				})
		},
		login(context, user) {
			context.state.user.loginInProcess = true;
			signInWithEmailAndPassword(AUTH, user.email, user.password)
				.then(() => {
					context.state.user.isLoggedIn = true;
					context.state.user.loginInProcess = false;
					setLs('user', user);
				})
				.catch((error) => {
					context.state.user.errorMsg = error.message;
					context.state.user.loginInProcess = false;
				})
		},
		logout(context) {
			signOut(AUTH).then(() => {
				context.state.user.isLoggedIn = false;
				removeFromLs('user');
			})
		},
		fetchCategories(context) {
			getColectionFromDatabase('Categories').then((data) => {
				data.forEach((element) => {
					context.state.categories.push(element.data());
				})
			});
		},
		fetchCourses(context) {
			getColectionFromDatabase('Courses').then((data) => {
                context.state.courses = [];
				data.forEach((element) => {
					context.state.isCatalogLoading = false;
					context.state.courses.push(element.data());
				})
			});
		},
        fetchOrders(context) {
			getColectionFromDatabase('Orders').then((data) => {
				data.forEach((element) => {
					context.state.orders.push(element.data());
				})
			});
		},
		fetchStaticContent(context) {
			getDocFromDatabase('Pages', 'Home').then((data) => {
				context.state.staticPageContent.home = data.data();
			});
			getDocFromDatabase('Pages', 'Courses').then((data) => {
				context.state.staticPageContent.courses = data.data();
			});
			getDocFromDatabase('Pages', 'About').then((data) => {
				context.state.staticPageContent.about = data.data();
			});
			getDocFromDatabase('Pages', 'Terms').then((data) => {
				context.state.staticPageContent.terms = data.data();
			});
			getDocFromDatabase('Pages', 'Contacts').then((data) => {
				context.state.staticPageContent.contacts = data.data();
			});
			getDocFromDatabase('Pages', 'Franchise').then((data) => {
				context.state.staticPageContent.franchise = data.data();
			});
			getDocFromDatabase('Pages', 'Gallery').then((data) => {
				context.state.staticPageContent.gallery = data.data();
			});
			getDocFromDatabase('Pages', 'Streams').then((data) => {
				context.state.staticPageContent.streams = data.data();
			});
			getDocFromDatabase('Pages', 'GeneralInfo').then((data) => {
				context.state.staticPageContent.generalInfo = data.data();
			});
		},
        fetchMetaInfo(context) {
            getDocFromDatabase('SEO', 'Meta').then((data) => {
				context.state.metaInfo = data.data();
			});
        },
		saveCategories(context, categories) {
			var list = [];
			categories.forEach((category) => {
				list.push(setDoc(doc(DB, 'Categories', category.id), category));
			});
			return Promise.all(list);
		},
		saveHomePage(context, pageContent) {
            return setDoc(doc(DB, 'Pages', 'Home'), pageContent);
		},
		saveAboutPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'About'), pageContent);
		},
		saveTermsPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Terms'), pageContent);
		},
		saveCoursesPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Courses'), pageContent);
		},
		saveStreamsPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Streams'), pageContent);
		},
		saveGalleryPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Gallery'), pageContent);
		},
		saveFranchisePage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Franchise'), pageContent);
		},
		saveGeneralInfo(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'GeneralInfo'), pageContent);
		},
		saveContactsPage(context, pageContent) {
			return setDoc(doc(DB, 'Pages', 'Contacts'), pageContent);
		},
		saveSeoPage(context, pageContent) {
            return setDoc(doc(DB, 'SEO', 'Meta'), pageContent)
		},
		saveCoursePage(context, product) {
            return setDoc(doc(DB, 'Courses', product.code), product)
		},
        saveOrdersPage(context, orders) {
			var list = [];
			orders.forEach((order) => {
				list.push(setDoc(doc(DB, 'Orders', order.docName), order));
			});
			return Promise.all(list);
		}
	}
})
