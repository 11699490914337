<template>
	<div class="page-loader" :class="{'hidden': isHidden}">
		<div class="loader ball-spin-fade-loader">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: ['isHidden'],
    mounted() {
        
    }
}
</script>
