import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'orders',
        component: () => import('../views/OrdersView.vue')
    },
    {
        path: '/courses',
        name: 'courses',
        component: () => import('../views/CoursesView.vue')
    },
    {
        path: '/categories',
        name: 'categories',
        component: () => import('../views/CategoriesView.vue')
    },
    {
        path: '/seo',
        name: 'seo',
        component: () => import('../views/SeoView.vue')
    },
    {
        path: '/new',
        name: 'newCourse',
        component: () => import('../views/NewCourseView.vue')
    },
    {
        path: '/courses/:id',
        name: 'course',
        component: () => import('../views/CourseView.vue')
    },
    {
        path: '/pages/contacts',
        name: 'contacts',
        component: () => import('../views/PageContactsView.vue')
    },
    {
        path: '/pages/gallery',
        name: 'gallery',
        component: () => import('../views/PageGalleryView.vue')
    },
    {
        path: '/pages/general',
        name: 'general',
        component: () => import('../views/PageGeneralInfoView.vue')
    },
    {
        path: '/pages/home',
        name: 'homepage',
        component: () => import('../views/PageHomeView.vue')
    },
    {
        path: '/pages/courses',
        name: 'coursespage',
        component: () => import('../views/PageCoursesView.vue')
    },
    {
        path: '/pages/about',
        name: 'about',
        component: () => import('../views/PageAboutView.vue')
    },
    {
        path: '/pages/terms',
        name: 'terms',
        component: () => import('../views/PageTermsView.vue')
    },
    {
        path: '/pages/franchise',
        name: 'franchise',
        component: () => import('../views/PageFranchiseView.vue')
    },
    {
        path: '/pages/streams',
        name: 'streams',
        component: () => import('../views/PageStreamsView.vue')
    },
    // {
    //     path: '/imagestorage',
    //     name: 'imagestorage',
    //     component: () => import('../views/ImageStorageView.vue')
    // }
]

const router = new VueRouter({
//   mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return {x: 0, y: 0};
	}
})

export default router
